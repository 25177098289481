
import './App.scss';
import React, {useState} from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import E404 from "./error404/home"
import About from './error404/about';
import About2 from './error404/about2';
import Owork from './error404/ourwork';
import PraA from './error404/pa';
import Services from './error404/services';
import S2 from './error404/s2';
import S3 from './error404/s3';
import S4 from './error404/s4';
import S5 from './error404/s5';
import S6 from './error404/s6';
import Team from './error404/team';
import Contact from './error404/contact';
import PrivacyPolicy from './error404/pp';
import Os from './error404/os';
import Training from './error404/training';
import International from './error404/ia';
import Tq from './error404/tq';
import Tas from './error404/tas';

function App() {
  return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={E404} />
          <Route path="/Introduction, Mission, & Vision" component={About} />
          <Route path="/Quality Control Assurance Partner" component={Tq} />
          <Route path="/International Affiliate" component={International} />
          <Route path="/Associates" component={Tas} />
          <Route path="/Core Values & Competencies" component={About2} />
          <Route path="/Our-Work" component={Owork} />
          <Route path="/Practice-Areas" component={PraA} />
          <Route path="/Services/Audit & Assurance" component={Services} />
          <Route path="/Services/Taxation & Regulatory" component={S2} />
          <Route path="/Services/Accounting & Bookkeeping" component={S3} />
          <Route path="/Services/Financial Advisory & Consultancy" component={S4} />
          <Route path="/Services/Other Services" component={S6} />
          <Route path="/Services/Training" component={Training} />
          <Route path="/The-Team" component={Team} />
          <Route path="/Other-Staff" component={Os} />
          <Route path="/Contact" component={Contact} />
          <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
        </Switch>
      </BrowserRouter>
  );
}

export default App;
