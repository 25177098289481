import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from "../components/header"
import Footer from "../components/footer"
import image from '../assets/bw.jpg';

class S3 extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <div className="App">
                <Header />
                <section>
                    <div className="sip2">
                        <img src={image} style={{objectFit: "contain", height: "100%", width: "100%"}} />
                    </div>
                    <div className="ag">
                        {/* <div className="h1 cb">Services</div> */}
                        <div className="cc">
                            <div className="h2">Accounting and Bookkeeping</div>
                            <div className="com">
                                <p style={{textAlign: 'left'}}>In an increasingly complex world today, having the best platforms that guarantee success for your business should not only be desired but also extremely essential. For all successful businesses, the efficient management of their accounting and bookkeeping records must be in the right hands. BETA provides a safe pair of hands that you can and should entrust with your accounting and bookkeeping needs. This is because we do not just talk bookkeeping and accounting, we do them. And not only do we do them, we also make sure these are done in such a way that it adds value to your organization. Below are some of the Accounting and Bookkeeping Services we offer: </p>
                                <ul style={{textAlign: 'left'}}>
                                    <li>Inventory count and inventory management </li>
                                    <li>Accounts payables management </li>
                                    <li>Accounts receivables management</li>
                                    <li>Daily General ledger maintenance  </li>
                                    <li>Payroll processing  </li>
                                    <li>Balance sheet generation and review  </li>
                                    <li>Expense’s classification  </li>
                                    <li>Sales commissions and VAT computations and treatment </li>
                                    <li>Company accounts reconciliation  </li>
                                    <li>Writing up books of accounts. </li>
                                </ul>
                                <p style={{textAlign: 'left'}}> Other Accounting Services include:</p>
                                <ul style={{textAlign: 'left'}}>
                                    <li>Budgeting & Forecasting  </li>
                                    <li>Financial statements preparation & reporting  </li>
                                    <li>Fixed assets management </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )

    }
}

export default S3;