import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from "../components/header"
import Footer from "../components/footer"
import image from '../assets/cx.jpg';

class Services extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <div className="App">
                <Header />
                <section>
                    <div className="sip2">
                        <img src={image} style={{objectFit: "contain", height: "100%", width: "100%"}} />
                    </div>
                    <div className="ag">
                        {/* <div className="h1 cb">Services</div> */}
                        <div className="cc">
                            <div className="h2">Audit and Assurance</div>
                            <div className="com">
                                <p style={{textAlign: 'left'}}>Our professional staff in the audit unit, gather information during the auditing process to understand fully the client’s business, internal controls and risk spectrum, and make the audit decisions. Then our financial advisory experts deliver perfect diagnostic tests. Our methods help our clients achieve their targeted demand for enhanced accountability. We do our job closely with our clients, and also independently. We do encourage open communication.</p>
                                <p style={{textAlign: 'left'}}>Below are some of the Audit & Assurance services that we offer.</p>
                                <ul style={{textAlign: 'left'}}>
                                    <li>Financial statement and other audit & assurance engagements  </li>
                                    <li>Internal Audits to provide assurance on controls and processes </li>
                                    <li>Tax Audit </li>
                                    <li>Special Assignments/ Investigations </li>
                                    <li>Financial management systems review-Independent assessment of financial statements </li>
                                    <li>Audit of prospective financial information </li>
                                    <li>Financial reporting, auditing and accounting standards advice </li>
                                    <li>Internal controls review </li>
                                    <li>IT system reviews </li>
                                    <li>Software selection </li>
                                    <li>Corporate governance reviews </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )

    }
}

export default Services;