import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from "../components/header"
import Footer from "../components/footer"
import image from '../assets/to9.jpg';

class S6 extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <div className="App">
                <Header />
                <section>
                    <div className="sip2">
                        <img src={image} style={{objectFit: "contain", height: "100%", width: "100%"}} />
                    </div>
                    <div className="ag">
                        {/* <div className="h1 cb">Services</div> */}
                        <div className="cc">
                            <div className="h2">Other Services </div>
                            <div className="com">
                                <ol style={{textAlign: 'left'}}>
                                    <li>Business Succession Planning </li>
                                    <li>Estate Planning (for individuals) </li>
                                    <li>Financial Planning (for individuals) </li>
                                    <li>Retirement Planning (for individuals) </li>
                                    <li>Training both general & tailor-made   </li>
                                    <li>Secretary services and business registration</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )

    }
}

export default S6;