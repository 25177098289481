import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from "../components/header"
import Footer from "../components/footer"
import image from '../assets/fg.jpg';

class S4 extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <div className="App">
                <Header />
                <section>
                    <div className="sip2">
                        <img src={image} style={{objectFit: "contain", height: "100%", width: "100%"}} />
                    </div>
                    <div className="ag">
                        {/* <div className="h1 cb">Services</div> */}
                        <div className="cc">
                            <div className="h2">Financial Advisory & Consultancy</div>
                            <div className="com">
                                <p style={{textAlign: 'left'}}>As a reputable accounting Firm, our goal is to use our knowledge and expertise to help boost our clients’ businesses financial performance and growth. Our financial advisory & consultancy services have no restrictions both in terms of business levels and/or geographical locations. Whether you are a government institution, corporate business or community organization, we operate with international standards that cater for your financial management needs.</p> <p>At BETA, we believe that implementing the best strategies on clients’ feasibility studies, business plans and project evaluations is a sure way to reaching their financial goals. On our clients’ behalf, we conduct in-depth research, draft convincing and actionable business plans and evaluate their projects for better optimization.  </p>
                                <p style={{textAlign: 'left'}}>BETA offers excellent financial solutions to clients whose goal is to optimize their business performance in order to boost their earning potential. Our principle is founded on the idea that every business deserves to grow. We have confidence in the competences of our dedicated and highly qualified staff equipped with cutting edge technology to drive change by providing solutions to our clients’ businesses. Our financial advisory & consultancy services are best in class because we ensure that our clients get nothing but the best. Our methods are practical and our results are concrete. We work with corporate businesses, government and community organizations to create individualized financial plans for savings, investment, retirement and insurance. We offer pragmatic information and guidance to help businesses plan their financial future. We provide invaluable information for taxation, investment and insurance decisions. We also focus on the financial needs of specific businesses or industries.</p>
                                <p style={{textAlign: 'left'}}> Here are some Financial Advisory & Consultancy services that we offer:  </p>
                                <ul style={{textAlign: 'left'}}>
                                    <li>Conducting Feasibility Studies   </li>
                                    <li>Business plan writing </li>
                                    <li>Project evaluations  </li>
                                    <li>Designing accounting information systems</li>
                                    <li>Computerization of manual accounting systems </li>
                                    <li>Financial evaluation of existing companies  </li>
                                    <li>Design and preparation of all cost accounting reports  </li>
                                    <li>Financial analysis, financial indicators and performance appraisal  </li>
                                    <li>Business recovery plans </li>
                                    <li>Capital restructuring plans  </li>
                                    <li>Assets and Business Valuations </li>
                                    <li>Carrying out due diligence for M & As </li>
                                    <li>Designing Administrative & Financial regulations.  </li>
                                    <li>Performing all procedures of companies’ incorporation  </li>
                                    <li>Business entity selection advice  </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )

    }
}

export default S4;