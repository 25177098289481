import React from "react"
import { useKeenSlider } from "keen-slider/react"
// import "./styles.css"
import "keen-slider/keen-slider.min.css"
import image from '../assets/blend.jpg';
import imagecx from '../assets/cx.jpg';
import imagecb from '../assets/cb.jpg';
import imagebw from '../assets/bw.jpg';
import imagefg from '../assets/fg.jpg';
import imagefu from '../assets/nfu12.jpg';

export default function Slider(props) {
  const [pause, setPause] = React.useState(false)
  const timer = React.useRef()
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    duration: 1000,
    dragStart: () => {
      setPause(true)
    },
    dragEnd: () => {
      setPause(false)
    },
  })

  React.useEffect(() => {
    sliderRef.current.addEventListener("mouseover", () => {
      setPause(true)
    })
    sliderRef.current.addEventListener("mouseout", () => {
      setPause(false)
    })
  }, [sliderRef])

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next()
      }
    }, 6000)
    return () => {
      clearInterval(timer.current)
    }
  }, [pause, slider])

  return (
    <>
      <div ref={sliderRef} className="keen-slider im">
        <div className="keen-slider__slide number-slide">
          <img src={image} style={{objectFit: "contain", height: "100%", width: "100%"}} />
        </div>
        <div className="keen-slider__slide number-slide">
          <img src={imagecb} style={{objectFit: "contain", height: "100%", width: "100%"}} />
        </div>
        <div className="keen-slider__slide number-slide">
          <img src={imagecx} style={{objectFit: "contain", height: "100%", width: "100%"}} />
        </div>
        <div className="keen-slider__slide number-slide">
          <img src={imagefu} style={{objectFit: "contain", height: "100%", width: "100%"}} />
        </div>
        <div className="keen-slider__slide number-slide">
          <img src={imagebw} style={{objectFit: "contain", height: "100%", width: "100%"}} />
        </div>
        <div className="keen-slider__slide number-slide">
          <img src={imagefg} style={{objectFit: "contain", height: "100%", width: "100%"}} />
        </div>
      </div>
    </>
  )
}