import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from "../components/header"
import Footer from "../components/footer"
import image from '../assets/to7.jpg';

class Tq extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <div className="App">
                <Header />
                <section>
                    <div className="sip">
                        <img src={image} style={{objectFit: "contain", height: "100%", width: "100%"}} />
                    </div>
                    <div className="ag">
                        <div className="tt">
                            {/* <div className="ant"></div> */}
                            <div className="antio">
                                <div>Quality control assurance Partner</div>
                                <div className="na">John Oliver Nipah </div>
                                <div style={{color: '#999'}}>John is a Chartered Accountant, with over eighteen (18) years post qualification experience in both public practice and industry. He has an in-depth understanding of Ghana Taxation, Tax audit, financial systems design and implementation, internal controls and risk management, Internal and external audits and assurance methodology. The blend of these skills, the added edge of having worked in four multinational entities: Compassion International, Unilever, DHL and Deloitte and his personal skills in leadership, are pivotal in his ability to provide world-class service to all the clients he manages.

<p>As an Associate at Baah & Associates, John managed the following United Nations Population Fund (UNFPA) sponsored Projects:
Strengthening the Integration of RH Services into Private Medical Practice of the Society of Private Medical and Dental Practitioners (SPMDP) for the year ended 31st December, 2004, which have been prepared in accordance with the required UNFPA accounting guidelines.</p>


Enhancing Population Policy Coordination and Implementation of the Ghana Population Council Secretariat for the year ended 31st December, 2004, which have been prepared in accordance with the required UNFPA accounting guidelines.

As a Senior Consultant for iNTEGRITAS in 2003, a professional accounting firm, John provided consulting service to VRA Accounts department for the preparation of Request for Proposal (RFP) for the purchase of an integrated financial and materials management software and Unilever Ghana Limited for the assessment of operational controls to enable Unilever Ghana Limited comply with section 404 requirements of Sarbanes-Oxley Act of USA.

<p>As Finance Projects Manager at Unilever, he successfully implemented an Enterprise Resource Planning (ERP) System. Hitherto, John was the Group Financial Accountant responsible for managing the accounting information system, maintenance and preparation of accounting records and financial reporting. He was also involved in the company’s operational controls assessment process. John had earlier worked as the Country Finance Manager for DHL Ghana responsible for monitoring and maintenance of accounting records, financial reporting and budgeting. </p>

At Deloitte, John rose from an Assistant Accountant to an Audit Senior and team leader for clients such as Agricultural Development Bank (ADB), Anglo-Gold Ashanti, Valco, Provident Insurance, Ghana Water Company, Westel and EU Micro Projects. 

John is also member of the Institute of Internal Auditors; Ghana and he also holds Executive MBA from the University of Ghana Business School.
</div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )

    }
}

export default Tq;