import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from "../components/header"
import Footer from "../components/footer"
import GoogleMapReact from 'google-map-react'
import { RiMapPin2Fill } from 'react-icons/ri';
import image from '../assets/to1.jpg';
const location = {
    address: 'Unnumbered House Spintex Road Near Entrance Pharmaceuticals, Accra',
    lat: 5.6377732,
    lng: -0.1228697,
  }
  const LocationPin = ({ text }) => (
    <div className="pin">
      {/* <Icon icon={locationIcon} className="pin-icon" /> */}
      <RiMapPin2Fill size={25} />
      <p className="pin-text">{text}</p>
    </div>
  )
class About2 extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <div className="App">
                <Header />
                <section>
                    <div className="sia">
                        <img src={image} style={{objectFit: "contain", height: "100%", width: "100%"}} />
                    </div>
                    <div className="ag">
                        {/* <div className="h1 cb">Beta & Associates</div> */}
                        <div className="cc">
                            <div className="h2">Core Values & Competencies.</div>
                            <div className="com" style={{fontSize: 14}}>
                                <p style={{textAlign: 'left', fontWeight: "700", fontSize: 20}}>Creativity</p>
                                <p style={{textAlign: 'left'}}>At BETA & Associates, we believe in a forward-thinking approach that encourages flexibility according to the needs of your organization. By no means does uniformity shadow the continuous critical thinking of our key professionals. We will innovate and prepare effective and efficient approaches specific to your organization. As a result, our assignments and the results become more interesting, enjoyable, and valuable for all involved.</p>
                                <p style={{textAlign: 'left', fontWeight: "700", fontSize: 20}}>Our Network</p>
                                <p style={{textAlign: 'left'}}>BETA & Associates was founded over 7 years ago and has grown into one of the premiere professional service firms in Ghana. BETA & Associates also has a network of national and international experience through our association with, and membership in the most progressive associations of Audit firms in the country. Our membership in associations allows us to provide a higher level of service to our clients, utilizing Association's network of expertise and state of the art training programs</p>
                                <p style={{textAlign: 'left', fontWeight: "700", fontSize: 20}}>Technology</p>
                                <p style={{textAlign: 'left'}}>Most of our engagements are performed using paperless technology. As our files are electronic, our entire engagement team, whether at Client’s or in our office, can access and review such documents thus streamlining our internal audit and review processes. In this way we have the ability to be "present" at client year-round, even when we are not physically located onsite.</p>
                                <p style={{textAlign: 'left', fontWeight: "700", fontSize: 20}}>Key Components</p>
                                <p style={{textAlign: 'left'}}>Along with effective communication, the use of technology, and creative analytical tools, we will serve your needs through the following key components of our process:</p>
                                <ul style={{textAlign: 'left'}}>
                                    <li><span style={{color: "#f00"}}>Thoughtful planning</span> that creates efficiency</li>
                                    <li><span style={{color: "#f00"}}>Committed staff</span> that work with your schedules and respect your time</li>
                                    <li><span style={{color: "#f00"}}>Document review</span> that is efficient and effective</li>
                                    <li><span style={{color: "#f00"}}>Interaction with your staff</span> to develop an understanding of your organizational needs and risks</li>
                                    <li><span style={{color: "#f00"}}>Strictly kept deadlines</span></li>
                                    <li><span style={{color: "#f00"}}>Confidentiality client's information is assured</span></li>
                                </ul>
                            </div>
                            <div className="map">
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: 'AIzaSyAiRTDXdEnNkI-AyV8-drs4oEaumsuiLGM' }}
                                    defaultCenter={location}
                                    defaultZoom={17}
                                >
                                    <LocationPin
                                    lat={location.lat}
                                    lng={location.lng}
                                    text={location.address}
                                    />
                                </GoogleMapReact>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )

    }
}

export default About2;