import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from "../components/header"
import Footer from "../components/footer"
import image from '../assets/to7.jpg';
import f1 from '../assets/f1.png';
import f2 from '../assets/f2.jpeg';
import f3 from '../assets/f3.jpeg';

class Team extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <div className="App">
                <Header />
                <section>
                    <div className="sip">
                        <img src={image} style={{objectFit: "contain", height: "100%", width: "100%"}} />
                    </div>
                    <div className="ag">
                        {/* <div className="h1 cb">Beta Associates</div> */}
                        <div className="cc">
                            <div className="h2">The Team.</div>
                            <div className="com">It is the policy of the firm to update the knowledge of its staff so as to be abreast with the changing face in the profession and also globalization. Consequently, we participate in related seminars and Continuing Professional Development seminars organized by professional bodies like Institute Chartered Accountants Ghana. We observe strict guidelines regarding signing of all correspondence, financial statements. Bills etc. so as to ensure accountability and preservation of our reputation</div>
                            {/* <NavLink to="/">Learn more about the agency&#x02192;</NavLink> */}
                        </div>
                        <br />
                        {/* <div className="asey">
                            <div className="ase1"><NavLink activeClassName="active2" to="/The-Team">Partners</NavLink></div>
                            <div className="ase2"><NavLink activeClassName="active2" to="/Other-Staff">Other Staff</NavLink></div>
                        </div> */}
                        <br />
                        <div className="tt">
                            <div className="ant">
                                <img src={f1} style={{objectFit: "contain", height: "100%", width: "100%"}} />
                            </div>
                            <div className="ant2">
                                <div>Managing Partner</div>
                                <div className="na">Emmanuel Banu</div>
                                <div style={{color: '#999'}}>
                                    Mr. Emmanuel Banu is the Managing Partner of BETA & Associates. He is a Chartered Accountant and a member of the Institute of Chartered Accountants, Ghana.
                                    <p>Mr. Banu has a Post Chartered Diploma, Master’s degree in Business Administration (MBA) in Management Information System from the University of Ghana, Legon and a Certificate in training and development from the Institute of Training & Development (UK). </p>
                                    He had his practical audit training at Egala Atitso and Associates (as it then was) one of the leading Local firms in Ghana.
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="tt">
                            <div className="ant">
                                <img src={f2} style={{objectFit: "contain", height: "100%", width: "100%"}} />
                            </div>
                            <div className="ant2">
                                <div>Partner -In charge</div>
                                <div className="na">Amos Teye </div>
                                <div style={{color: '#999'}}>
                                    Amos Teye is the Partner-In charge of BETA & Associates. He is a Chartered Accountant and a member of the Institute of Chartered Accountants, Ghana. 
                                    <p>Mr. Teye has Master’s degree in Business Administration (MBA) in Accounting and Finance from University of Professional Studies, Accra and Higher National Diploma in Accountancy from Koforidua Technical University, Koforidua.</p>
                                    Mr. Teye had his full practical training at UHY Voscon Chartered Accountants as one of the mid-tier international firms in Ghana.
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                    </div>
                </section>
                <Footer />
            </div>
        )

    }
}

export default Team;