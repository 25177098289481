import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from "../components/header"
import Footer from "../components/footer"
import image from '../assets/to7.jpg';

class Os extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <div className="App">
                <Header />
                <section>
                    <div className="sip">
                        <img src={image} style={{objectFit: "contain", height: "100%", width: "100%"}} />
                    </div>
                    <div className="ag">
                        {/* <div className="h1 cb">Beta Associates</div> */}
                        <div className="cc">
                            <div className="h2">The Team.</div>
                            <div className="com">It is the policy of the firm to update the knowledge of its staff so as to be abreast with the changing face in the profession and also globalization. Consequently, we participate in related seminars and Continuing Professional Development seminars organized by professional bodies like Institute Chartered Accountants Ghana. We observe strict guidelines regarding signing of all correspondence, financial statements. Bills etc. so as to ensure accountability and preservation of our reputation</div>
                            {/* <NavLink to="/">Learn more about the agency&#x02192;</NavLink> */}
                        </div>
                        {/* <div className="asey">
                            <div className="ase3"><NavLink activeClassName="active2" to="/The-Team">Partners</NavLink></div>
                            <div className="ase4"><NavLink activeClassName="active2" to="/Other Staff">Other Staff</NavLink></div>
                        </div> */}
                        <br />
                        <div className="tt">
                            {/* <div className="ant"></div> */}
                            <div className="antio">
                                <div>Manager </div>
                                <div className="na">Mr. Nicolas K.A. Amoah</div>
                                <div style={{color: '#999'}}>
                                    He holds a Bachelor of Science in Administration from the University of Ghana, Legon and currently pursuing an MBA degree in accounting on part-time. 

                                    <p>He is a certified SCALA Technical and Implementation Consultant and Microsoft Certified System Engineer. 

                                    </p><p>He has several years of work experience as a consultant in ICT and Auditing. He is an accomplished IS Auditor and Security Professional with CISA, CISSP, CFE, and ISO27001LA certifications. He has over 20 years’ experience in the Planning, design and implementation of Enterprise Resource Planners software (ERP) Security and Business Continuity/Disaster Recovery plan. He is a team player and a hard-working person.

                                    </p>He also has Practical experience in Donor (including; World Bank, IFAD, AfDB, EU and DFID) funded projects financial management: Project financial audit, fund management, cash management, financial analysis/ reporting, asset management. He previously worked as Auditor with DFK Professionals, a Chartered Accounting firm. 

                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="tt">
                            {/* <div className="ant"></div> */}
                            <div className="antio">
                                <div>Manager</div>
                                <div className="na">Michael Ayesu-Appiah</div>
                                <div style={{color: '#999'}}>Over twenty years of extensive experience in Accountancy, Auditing & Investigations, and General Management. 

His experience spans across diverse multinational and local  industries 
 in oil & gas, cocoa inputs, hospitality, and gold dealership.

A product of Tettey Usher & Partners Chartered Accountants ( merged with Deloitte & Touche ). This is where he had his Accountancy & Audit training, experience and exposure to numerous clients in various industries including Ghana Commercial Bank, most rural banks, Chemico, State Hotels and  Produce Buying Company.   

<p>His suggestions & encouragement  to the board of a number of Rural Banks he audited is the emergence of the numerous rural bank branches across the country.

An ICA (Ghana ) finalist, holds a Diploma in Hospitality & Tourism from the Association of Business Executives (UK)</p></div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="tt">
                            {/* <div className="ant"></div> */}
                            <div className="antio">
                                <div>Audit and Administration Assistant</div>
                                <div className="na">Mr. Zawii Osman Zimeweh</div>
                                <div style={{color: '#999'}}>Mr. Zawii Osman Zimeweh is an Audit and Administrative Assistant in charge of all administrative task of the firm. Mr Zawii Join BETA & Associates after his service and work meticulously with or without supervision.
                                <p>Mr. Zawii holds High National Diploma (HND) in statistics from Accra Technical University.</p></div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="tt">
                            {/* <div className="ant"></div> */}
                            <div className="antio">
                                <div>Audit Assistant</div>
                                <div className="na">Mrs. Sethlina Anderson</div>
                                <div style={{color: '#999'}}>Mr. Sethlina is an Audit Assistant who play a major role of gather audit evidence during Audit assignments. Mrs. Anderson is competent in her field and pay attention to details.  Prior to Joining BETA & Associates, Mrs. Anderson has worked with Bank of Africa as Back-office staff.
                                <p>Mrs. Anderson holds Bachelors of Commence from University of Cape Coast. Mrs. Anderson started writing ICAG professional exams from level 1 and now left with Financial Reporting to complete level 2.</p></div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="tt">
                            {/* <div className="ant"></div> */}
                            <div className="antio">
                                <div>Auditor</div>
                                <div className="na">Frederick Ohemeng Hemans</div>
                                <div style={{color: '#999'}}>Frederick is an accounting professional with over 15 years of experience in the private sector, including 4 years of experience in management position; he has some exposure in the public sector. His extensive exposure in the private sector has enhanced his understanding of different industries and equally sharpened his application of financial modelling to strategic problem resolution.
                                <p>Before joining the non-life insurance industry, he had worked in the health insurance sector, HR industry, salt industry and external/ internal audit sector. </p>
                                <p>He was Internal Auditor at Prime Insurance, Accountant for L’AINE Services, finance officer for Panbros Salt Industries and he worked for three audit firms in different capacities. </p>
                                <p>His external and internal audit background runs the gamut from financial audits through risk-based audit to governance audit in particularly private entities. Frederick has supervised and supported audit team members and accounts team members.</p>
                                <p>Fredrick work in the capacity of Auditor with BETA & Associates. Frederick is a finalist of the Association of Chartered Certified Accountants (ACCA) and a holder of Higher National Diploma (HND) accounting.</p>
                            </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="tt">
                            {/* <div className="ant"></div> */}
                            <div className="antio">
                                <div>Auditor</div>
                                <div className="na">Francis Asare Kwao</div>
                                <div style={{color: '#999'}}>Multifaceted, result-driven proficient with 5+ years of wide-ranging experience developing exceptional relationship with clients, colleagues, superb project leadership abilities and personnel management skills with strong expertise in Accountancy, Tax, Finance and Corporate practice. Proven problem solving and analytical thinking skills, a fast-learning curve, and capacity to adapt to evolving industry trends. A committed professional with the drive skill-set to excel in a fast-paced leadership role augmenting Company’s ability to capture profitable markets.
<p>Prior to joining HMI Management Services as Head of Account in September 2020, he worked in the following companies: Magnet Micro Credit as Internal Auditor, Mazars Ghana where he played dual role in both Finance and Audit department as the Finance and Audit Associate.</p>
<p>Francis holds Degree in Accounting from the University of Professional Studies, Accra and currently pursuing his MBA in Accounting and Finance from the same University.</p>
</div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )

    }
}

export default Os;