import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from "../components/header"
import Footer from "../components/footer"
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import image from '../assets/to6.jpg';

import GoogleMapReact from 'google-map-react'
import { RiMapPin2Fill } from 'react-icons/ri';
const location = {
    address: 'Unnumbered House Spintex Road Near Entrance Pharmaceuticals, Accra',
    lat: 5.6377732,
    lng: -0.1228697,
  }
  const LocationPin = ({ text }) => (
    <div className="pin">
      {/* <Icon icon={locationIcon} className="pin-icon" /> */}
      <RiMapPin2Fill size={25} />
      <p className="pin-text">{text}</p>
    </div>
  )
class Contact extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <div className="App">
                <Header />
                <section>
                    <div className="sip">
                        <img src={image} style={{objectFit: "contain", height: "100%", width: "100%"}} />
                    </div>
                    <div className="cont">
                        <div>
                            <div className="ch">Contact Us</div>
                            <div className="ch2">BETA & ASSOCIATES</div>
                            <div>(CHARTERED ACCOUNTANTS & MANAGEMENT CONSULTANTS)</div>
                            <div>P.O. BOX MD 396</div>
                            <div>MADINA-ACCRA, GHANA</div>

                            <div className="ch2">Tel:</div>
                            <div>
                                <div className="st2"><a href="tel:0245797718">+233(0) 245797718</a></div>
                                <div className="st2"><a href="tel:0208240016">+233(0) 208240016</a></div>
                                <div className="st2"><a href="tel:0244714481">+233(0) 244714481</a></div>
                                <div className="st2"><a href="tel:0543476192">+233(0) 543476192</a></div>
                                <div className="st2"><a href="tel:0501481177">+233(0) 501481177</a></div>
                            </div>
                            <div className="ch2">Email:</div>
                            <div><a href="mailto:banubenser@gmail.com">banubenser@gmail.com</a></div>
                            <div><a href="mailto:info@beta-associates.com">info@beta-associates.com</a></div>

                            <div>The difference is our Commitment…</div>
                        </div>
                        <div className="hgbv">
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: 'AIzaSyAiRTDXdEnNkI-AyV8-drs4oEaumsuiLGM' }}
                                defaultCenter={location}
                                defaultZoom={17}
                            >
                                <LocationPin
                                lat={location.lat}
                                lng={location.lng}
                                text={location.address}
                                />
                            </GoogleMapReact>
                        </div>
                    </div>
                </section>
                <footer className="foot2">
                    <div className="footer">
                        <div className="sbl">
                            <a target="_blank" href="https://web.facebook.com/BETA-Associates-Chartered-Accountants-587997258546021/"><FaFacebookF /></a>
                            <a target="_blank" href="https://mobile.twitter.com/associates_beta"><FaTwitter /></a>
                            <a target="_blank" href="https://www.linkedin.com/company/beta-associates-chartered-accountants/"><FaLinkedinIn /></a>
                        </div>
                        <div>&copy; 2021 BETA & Associates | <a href="/Contact">Contact Us</a> | <a href="/PrivacyPolicy">Privacy Policy</a> | All rights reserved.</div>
                        <div>Designed by Jackson Osei Kojo</div>
                    </div>
                </footer>
            </div>
        )

    }
}

export default Contact;