import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from "../components/header"
import Footer from "../components/footer"
import GoogleMapReact from 'google-map-react'
import { RiMapPin2Fill } from 'react-icons/ri';
import image from '../assets/to1.jpg';
const location = {
    address: 'Unnumbered House Spintex Road Near Entrance Pharmaceuticals, Accra',
    lat: 5.6377732,
    lng: -0.1228697,
  }
  const LocationPin = ({ text }) => (
    <div className="pin">
      {/* <Icon icon={locationIcon} className="pin-icon" /> */}
      <RiMapPin2Fill size={25} />
      <p className="pin-text">{text}</p>
    </div>
  )
class About extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <div className="App">
                <Header />
                <section>
                    <div className="sia">
                        <img src={image} style={{objectFit: "contain", height: "100%", width: "100%"}} />
                    </div>
                    <div className="ag">
                        {/* <div className="h1 cb">Beta & Associates</div> */}
                        <div className="cc">
                            <div className="h2">Introduction, Mission, & Vision.</div>
                            <div className="com" style={{fontSize: 14}}>
                                <p style={{textAlign: 'left'}}>BETA & Associates is a firm registered by the Institute of Chartered Accountants, Ghana (ICAG) with its associate’s firm John Nipah and Associates. We provide wide range of business services to government, companies in a variety of industries including Securities and Exchange Commission and Bank of Ghana regulated companies, non-profit organization and individuals. We believe in the value of relationships, and truly believe that our success is a result of your success. We are committed to providing close, personal attention to our clients and take pride in giving you the assurance that the personal assistance you receive comes from years of advanced training, technical experience and financial acumen. </p>
                                <p style={{textAlign: 'left'}}>The firm specialises in auditing and assurance services, business advisory and consultation, tax planning and accounting. We are proud to be one of the professional auditing firms in Ghana, and we know that our growth and success are directly link to our commitment to excellence and professional service we provide to our clients. </p>
                                <p style={{textAlign: 'left'}}>In response to a growing focus on highly individualized accounting and related financial requirements, BETA & Associates was founded as a full member of the Institute of Chartered Accountants, Ghana. Since the time of registration, we have enjoyed unusual growth resulting from associates with diversified clients including individuals, trusts, companies and corporations and non-profit organizations.  Our firm profile is unique in that we provide a full range of the highest quality services a cost, which is considerably lower than that of large national and international accounting firms. </p>
                                <p style={{textAlign: 'left'}}>Today’s fast- moving commercial environment places constant pressure on many companies. Developing the skills and capabilities needed to gain competitive edge and grow market share in the challenging environment is essential to their success. To achieve this both the company’s management and their advisors must adopt a more entrepreneurial, pro-active approach to doing business. The goal of BETA has always been to offer advice and services that bring measurable added value to our clients. We pride ourselves with the ability to develop innovative business solutions as part of a long term, stable business relationship that is built on mutual trust, respect and a shared believe that a positive attitude makes everything. It is in this privileged position as a true partner, instrumental in the success of our clients, that the strengths of BETA become most apparent.</p>
                                <p style={{textAlign: 'left'}}>We pride ourselves on our knowledge and understanding of our clients and their organizations. When we work Clients, we will implement a service approach to consistently produce high quality results in an efficient manner. It is our continued goal to be a valued partner and resource to your organization. We realize that timing is an important aspect of quality service and will carefully coordinate our work and delivery timelines with you to ensure it works with your schedules and that your expectations are met.</p>
                                <p style={{textAlign: 'left', fontWeight: "700", fontSize: 20}}>Our Mission Statement </p>
                                <p style={{textAlign: 'left'}}>BETA (Chartered Accountants) is an assurance and business advisory firm established to provide its clients and community with high quality and professional services. Our main goal is finding new ways to serve you through training and development, and recruiting competent specialist with required skills and experience in new areas so as to provide you with high quality service that you expect. </p>
                                <p style={{textAlign: 'left', fontWeight: "700", fontSize: 20}}>Our Vision Statement</p>
                                <p style={{textAlign: 'left'}}>To be one of the leading Auditing and Consulting firms in Ghana in terms of providing professional services, number of employees and number of partners and representatives.</p>
                                <p style={{textAlign: 'left', fontWeight: "700", fontSize: 20}}>Our value </p>
                                <p style={{textAlign: 'left'}}>At BETA & Associates, we are dedicated to quality. It is through offering our own expertise in various Assurance and Consulting services and maintaining an ongoing system of quality review that offer you professional services.  </p>
                                <p style={{textAlign: 'left', fontWeight: "700", fontSize: 20}}>Quality Policy </p>
                                <ul style={{textAlign: 'left'}}>
                                    <li>Our philosophy is to provide a high quality, professional and personal service to our clients </li>
                                    <li>To build a solid base with flexible policies to create an environment for innovation and development.</li>
                                    <li>To deepen the meaning of quality to each member of the company regardless of his/her job description.</li>
                                    <li>To progress the standard level of commitment to customers in fulfilling their demands</li>
                                </ul>
                                <p style={{textAlign: 'left', fontWeight: "700", fontSize: 20}}>Experience of our firm </p>
                                <p style={{textAlign: 'left'}}>We have over the years performed varied assignments as individuals and as a firm we therefore have enough experience to excellently execute the assignments should you engage our firm.</p>
                                <p style={{textAlign: 'left', fontWeight: "700", fontSize: 20}}>Our Professional Services </p>
                                <p style={{textAlign: 'left'}}>Why Choose BETA & Associates</p>
                                <p style={{textAlign: 'left'}}><b>Our culture and values -</b> BETA & Associates have developed a culture and values which ensure mutually beneficial relationship between our clients and our people that ensure that client service excellence is an integral part of the way we do business. </p>
                                <p style={{textAlign: 'left'}}>We strive to live the following culture and values: </p>
                                <ul style={{textAlign: 'left'}}>
                                    <li>Tailor made audit programme </li>
                                    <li>Tentative audit time schedule </li>
                                    <li>Generating significant growth and superior stakeholders’ value </li>
                                    <li>Range of technical and professional experiences </li>
                                    <li>Give clear opinions and insights </li>
                                    <li>At any time before, during or after the completion of each engagement divulge any confidential information communicated to or acquired by us or disclosed by the Client in the course of carrying out the engagement. No such information shall be used by us on any other project without prior written approval by the respective parties. </li>
                                </ul>
                            </div>
                            <div className="map">
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: 'AIzaSyAiRTDXdEnNkI-AyV8-drs4oEaumsuiLGM' }}
                                    defaultCenter={location}
                                    defaultZoom={17}
                                >
                                    <LocationPin
                                    lat={location.lat}
                                    lng={location.lng}
                                    text={location.address}
                                    />
                                </GoogleMapReact>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )

    }
}

export default About;