import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from "../components/header"
import Footer from "../components/footer"
import Slider from "../components/slide"
class Home extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <div className="App">
                <Header />
                <section>
                    <Slider />
                    <div className="ag">
                        <div className="h1 cb">Welcome!</div>
                        <div className="cc">
                            <div className="com"><p>BETA & Associates is a firm registered by the Institute of Chartered Accountants, Ghana (ICAG) with its associate’s firm John Nipah and Associates and TRC Chartered Accountants.</p><p>We provide wide range of business services to government, companies in a variety of industries including Securities and Exchange Commission and Bank of Ghana regulated companies, non-profit organization and individuals. We believe in the value of relationships, and truly believe that our success is a result of your success. </p>We are committed to providing close, personal attention to our clients and take pride in giving you the assurance that the personal assistance you receive comes from years of advanced training, technical experience and financial acumen.</div>
                            <NavLink to="/Introduction, Mission, & Vision">Learn more about our firm&#x02192;</NavLink>
                        </div>
                    </div>
                    <div className="av">
                        <div className="mi">
                            <div className="qw">
                                <div className="qw1">Commited With</div>
                                <div className="qw2">Professionalism</div>
                            </div>
                            <div className="qt">
                                <div className="qt1">Our Practice Areas</div>
                                <div  className="qt2">
                                    <NavLink to="/Services/Audit & Assurance">Audit & Assurance</NavLink>
                                    <NavLink to="/Services/Taxation & Regulatory">Taxation & Regulatory</NavLink>
                                    <NavLink to="/Services/Accounting & Bookkeeping">Accounting & Bookkeeping</NavLink>
                                    <NavLink to="/Services/Financial Advisory">Financial Advisory & Consultancy</NavLink>
                                    <NavLink to="/Services/Training">Training</NavLink>
                                    <NavLink to="/Services/Other Services">Other Services</NavLink>
                                </div>
                                <div className='eer'></div>
                                <NavLink className="nim" to="/Practice-Areas">SEE EVERYTHING WE DO</NavLink>
                            </div>
                        </div>
                    </div>
                    
                    <div className="vc">
                        <div className="one">
                            <div className="ima"></div>
                            <div className="sd">
                                <div className="kl">
                                    <div>
                                        Mr. Emmanuel Banu is the Managing Partner of BETA & Associates. He is a Chartered Accountant and a member of the Institute of Chartered Accountants, Ghana.
                                        Mr. Banu has a Post Chartered Diploma, Master’s degree in Business Administration (MBA) in Management Information System from the University of Ghana, Legon and a Certificate in training and development from the Institute of Training & Development (UK). 
                                        He had his practical audit training at Egala Atitso and Associates (as it then was) one of the leading Local firms in Ghana.
                                    </div>
                                </div>
                                <div className="jh">
                                    <div className="xc">Emmanuel Banu</div>
                                    <div className="xc2">Managing Partner</div>
                                </div>
                            </div>
                        </div>
                        <div className="two">
                            <div className="sd">
                                <div className="kl">
                                    <div>
                                        Amos Teye is the Partner-In charge of BETA & Associates. He is a Chartered Accountant and a member of the Institute of Chartered Accountants, Ghana. 
                                        Mr. Teye has Master’s degree in Business Administration (MBA) in Accounting and Finance from University of Professional Studies, Accra and Higher National Diploma in Accountancy from Koforidua Technical University, Koforidua.
                                        Mr. Teye had his full practical training at UHY Voscon Chartered Accountants as one of the mid-tier international firms in Ghana.
                                    </div>
                                </div>
                                <div className="jh">
                                    <div className="xc">Amos Teye</div>
                                    <div className="xc2">Partner In Charge</div>
                                </div>
                            </div>
                            <div className="ima"></div>
                        </div>
                    </div>
                    <div className="vc2">
                        <div className="one">
                            <div className="ima"></div>
                            <div className="sd">
                                <div className="kl">
                                    <div>
                                        Mr. Emmanuel Banu is the Managing Partner of BETA & Associates. He is a Chartered Accountant and a member of the Institute of Chartered Accountants, Ghana.
                                        Mr. Banu has a Post Chartered Diploma, Master’s degree in Business Administration (MBA) in Management Information System from the University of Ghana, Legon and a Certificate in training and development from the Institute of Training & Development (UK). 
                                        He had his practical audit training at Egala Atitso and Associates (as it then was) one of the leading Local firms in Ghana.
                                    </div>
                                </div>
                                <div className="jh">
                                    <div className="xc">Emmanuel Banu</div>
                                    <div className="xc2">Managing Partner</div>
                                </div>
                            </div>
                        </div>
                        <div className="two">
                            <div className="ima"></div>
                            <div className="sd">
                                <div className="kl">
                                    <div>
                                        Amos Teye is the Partner-In charge of BETA & Associates. He is a Chartered Accountant and a member of the Institute of Chartered Accountants, Ghana. 
                                        Mr. Teye has Master’s degree in Business Administration (MBA) in Accounting and Finance from University of Professional Studies, Accra and Higher National Diploma in Accountancy from Koforidua Technical University, Koforidua.
                                        Mr. Teye had his full practical training at UHY Voscon Chartered Accountants as one of the mid-tier international firms in Ghana.
                                    </div>
                                </div>
                                <div className="jh">
                                    <div className="xc">Amos Teye</div>
                                    <div className="xc2">Partner In Charge</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kiw">
                        <div className="hh">Some Partners We Work With</div>
                        <div className="pp">
                            <div className="p1"></div>
                            <div className="p2"></div>
                            <div className="p3"></div>
                            <div className="p4"></div>
                            <div className="p5"></div>
                            <div className="p6"></div>
                            <div className="p7"></div>
                            <div className="p8"></div>
                            <div className="p9"></div>
                            <div className="p10"></div>
                            <div className="p11"></div>
                            <div className="p12"></div>
                            <div className="p13"></div>
                        </div>
                    </div>
                </section>
                
                <Footer />
            </div>
        )

    }
}

export default Home;