import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from "../components/header"
import Footer from "../components/footer"
import image from '../assets/nfu12.jpg';

class Training extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <div className="App">
                <Header />
                <section>
                    <div className="sip2">
                        <img src={image} style={{objectFit: "contain", height: "100%", width: "100%"}} />
                    </div>
                    <div className="ag">
                        {/* <div className="h1 cb">Services</div> */}
                        <div className="cc">
                            <div className="h2">Training</div>
                            <div className="com">
                                <p style={{textAlign: 'left'}}>As part of our corporate social responsibilities, we have decided to train newly recruited staff of clients and/or students and existing staff in Accounting and finance departments.</p>
                                <p style={{textAlign: 'left'}}>Some of the training we offer includes:</p>
                                <ul style={{textAlign: 'left'}}>
                                    <li>Software (ERP Tally, Quickbooks, Sage, Pastel, advanced excel, etc) Training </li>
                                    <li>Basic filing forms filling for filing at Ghana Revenue Authority and Registrar General Department  </li>
                                    <li>IFRS implementation and training </li>
                                    <li>Report writing </li>
                                    <li>Payroll handling training</li>
                                    <li>Petty cash and finance management </li>
                                    <li>Working paper preparation for newly opened audit firms' staff</li>
                                    <li>External and internal audit process and procedures training</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )

    }
}

export default Training;