import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from "../components/header"
import Footer from "../components/footer"
import image from '../assets/to3.jpg';

class PraA extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <div className="App">
                <Header />
                <section>
                    <div className="sip">
                        <img src={image} style={{objectFit: "contain", height: "100%", width: "100%"}} />
                    </div>
                    <div className="ag">
                        {/* <div className="h1 cb">Beta Associates</div> */}
                        <div className="cc">
                            <div className="h2">Practice Areas.</div>
                            <div className="com">
                                <p style={{textAlign: 'left'}}>Our operations cut across both the private and the public sectors, with our rich experience and technical know-how, we are poised at strengthening the management capacity of our clients through the provision of need-oriented Professional Services in the following sectors of the economy:</p>
                                <ul>
                                    <li style={{textAlign: 'left'}}>Religious Organizations</li>
                                    <li style={{textAlign: 'left'}}>Alternative Energy</li>
                                    <li style={{textAlign: 'left'}}>Oil & Gas</li>
                                    <li style={{textAlign: 'left'}}>Mining</li>
                                    <li style={{textAlign: 'left'}}>Manufacturing</li>
                                    <li style={{textAlign: 'left'}}>Technology, Media & Telecommunications (TMT)</li>
                                    <li style={{textAlign: 'left'}}>Automobile</li>
                                    <li style={{textAlign: 'left'}}>Consumer Business</li>
                                    <li style={{textAlign: 'left'}}>Import and Export</li>
                                    <li style={{textAlign: 'left'}}>Financial Services</li>
                                    <li style={{textAlign: 'left'}}>Public Sector</li>
                                    <li style={{textAlign: 'left'}}>Central & Local Government</li>
                                    <li style={{textAlign: 'left'}}>Public / Private Health & Education</li>
                                    <li style={{textAlign: 'left'}}>Public Utilities & Social Housing</li>
                                    <li style={{textAlign: 'left'}}>Non-government Organization / Not for making Profit Organizations</li>
                                    <li style={{textAlign: 'left'}}>Professional Services</li>
                                    <li style={{textAlign: 'left'}}>Agriculture</li>
                                    <li style={{textAlign: 'left'}}>Pest Control Companies</li>
                                    <li style={{textAlign: 'left'}}>Educational sector</li>
                                    <li style={{textAlign: 'left'}}>Health sector</li>
                                    <li style={{textAlign: 'left'}}>Hospitality industry</li>
                                    <li style={{textAlign: 'left'}}>Pharmaceutical industry</li>
                                    <li style={{textAlign: 'left'}}>Etc.</li>
                                </ul>
                            </div>
                            {/* <NavLink to="/">Learn more about the agency&#x02192;</NavLink> */}
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )

    }
}

export default PraA;