import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from "../components/header"
import Footer from "../components/footer"

class PrivacyPolicy extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <div className="App">
                <Header />
                <section>
                    {/* <div className="sia"></div> */}
                    <div className="ag">
                        <div className="h1 cb">Beta And Associates</div>
                        <div className="cc">
                            <div className="h2">Privacy Policy</div>
                            <div className="com">
                                <h3 style={{textAlign: 'left'}}>Internet privacy policy</h3>
                                <p style={{textAlign: 'left'}}>The data controller collecting the data described herein is the BETA & Associates firm to which the visitor has submitted their data. Personal data collected by BETA & Associates may be transferred to other individual Companies that may contract BETA & Associates to recruit staff for them where it is necessary to meet the purpose for which the visitor has submitted the information. By submitting data on BETA & Associates’ website, the visitor is providing explicit consent to transborder transmission of data collected on the website for the fulfilment of their voluntary requests.</p>
                                <h3 style={{textAlign: 'left'}}>Data collection</h3>
                                <p style={{textAlign: 'left'}}>We collect only personally identifiable information that is specifically and voluntarily provided by visitors to BETA & Associates’ website. BETA & Associates receives limited identifiable information, such as name, title, company address, email address, and telephone and fax numbers, from website visitors. Typically, identifying information is collected to:</p>
                                <ul>
                                    <li style={{textAlign: 'left'}}>Register for certain areas of the site</li>
                                    <li style={{textAlign: 'left'}}>Inquire for further information</li>
                                    <li style={{textAlign: 'left'}}>Distribute requested reference materials</li>
                                </ul>
                                <h3 style={{textAlign: 'left'}}>Use of data</h3>
                                <p style={{textAlign: 'left'}}>A user may choose to provide personal information in the following examples:</p>
                                <ul>
                                    <li style={{textAlign: 'left'}}>Order publications</li>
                                    <li style={{textAlign: 'left'}}>Submit resumes or work history information</li>
                                    <li style={{textAlign: 'left'}}>Participate in “join our mailing list” initiatives</li>
                                    <li style={{textAlign: 'left'}}>Participate in bulletin boards, discussion or message forums</li>
                                    <li style={{textAlign: 'left'}}>Contact us for further information</li>
                                    <li style={{textAlign: 'left'}}>Enter Quick Surveys, Quiz’s or Benchmarking Surveys</li>
                                    <li style={{textAlign: 'left'}}>Register for events and conferences</li>
                                    <li style={{textAlign: 'left'}}>Register for premium online services</li>
                                </ul>
                                <p style={{textAlign: 'left'}}>If you would like to find out more about the different categories of information collected, please review the data collection section.</p>
                                <p style={{textAlign: 'left'}}>Information attained by the site is used only for the intended purpose stated at the time that the information is collected. This data is not shared with other entities for secondary or unrelated purposes, or shared with a third party, unless otherwise disclosed at the point of collection. If there is an instance where such information may be shared, the visitor will be asked for permission beforehand.</p>
                                <p style={{textAlign: 'left'}}>BETA & Associates makes every practical effort to avoid excessive or irrelevant collection of data. If a visitor believes the site has collected excessive information, we encourage the visitor to contact us at info@beta-associates.com to raise any concerns.</p>
                                <p style={{textAlign: 'left'}}>Except for the mailing list initiative described above, where visitors explicitly choose to receive specific BETA & Associates marketing or other materials, BETA & Associates will not use personal data collected from our websites to facilitate unsolicited marketing activities.</p>
                                <h3 style={{textAlign: 'left'}}>Cookies and log files</h3>
                                <p style={{textAlign: 'left'}}>Cookies may be used on some pages of our site. “Cookies” are small text files placed on your hard drive that assist us in providing a more customised website experience. For example, a cookie can be used to store registration information in an area of the site so that a user does not need to re-enter it on subsequent visits to that area. It is BETA & Associates’ policy to use cookies to make navigation of our websites easier for visitors and to facilitate efficient registration procedures. Site statistics are compiled by third parties, and therefore your IP address will be passed to third parties for statistical reporting only.</p>
                                <p style={{textAlign: 'left'}}>If you are concerned about cookies, most browsers permit individuals to decline cookies. In most cases, a visitor may refuse a cookie and still fully navigate our website; however other functionality in the site may be impaired. After termination of the visit to our site, you can always delete the cookie from your system if you wish.</p>
                                <p style={{textAlign: 'left'}}>In order to properly manage our website, we may anonymously log information on our operational systems, and identify categories of visitors by items such as domains and browser types. These statistics are reported in the aggregate to our webmasters. This is to ensure that our website presents the best web experience for visitors and is an effective information resource.</p>
                                <h3 style={{textAlign: 'left'}}>Third parties</h3>
                                <p style={{textAlign: 'left'}}>It is BETA & Associates’ policy only to disclose information to third parties under the following circumstances:</p>
                                <ul>
                                    <li style={{textAlign: 'left'}}>As required by law through subpoena, search warrant or other legal process</li>
                                    <li style={{textAlign: 'left'}}>When explicitly requested by a visitor</li>
                                    <li style={{textAlign: 'left'}}>When required to deliver publications or reference materials requested by a visitor</li>
                                    <li style={{textAlign: 'left'}}>When required to facilitate conferences or events hosted by a third party</li>
                                </ul>
                                <p style={{textAlign: 'left'}}>BETA & Associates’ policy is to disclose information to third parties upon visitors submitting their requests (e.g., when ordering a publication, we display the party fulfilling the order).</p>
                                <p style={{textAlign: 'left'}}>BETA & Associates websites do not collect or compile personally identifying information for dissemination or sale to outside parties for consumer marketing purposes, or host mailings on behalf of third parties.</p>
                                <h3 style={{textAlign: 'left'}}>Third party links</h3>
                                <p style={{textAlign: 'left'}}>There are several places throughout www.beta-associates.com that may link to other websites that do not operate under www.beta-associates.com ’s privacy practices. When you link to other websites, BETA & Associates’ privacy practices no longer apply. We encourage visitors to review each site’s privacy policy before disclosing any personally identifiable information.</p>
                                <h3 style={{textAlign: 'left'}}>Data retention</h3>
                                <p style={{textAlign: 'left'}}>Some of the information we receive is not retained. For example, we usually do not keep mailing addresses for white papers. Contact information about visitors (such as information generated though registration for access to areas on the site) will be kept as long as the information is required to completely service the contact request or until a user request that we delete that information. Mailing list information, discussion posts and email are kept for only the period of time considered reasonable to facilitate the visitor’s requests. Resumes are disposed of when they are either no longer under consideration, or are considered dated by our Human Resources departments.</p>
                                <h3 style={{textAlign: 'left'}}>Choices</h3>
                                <p style={{textAlign: 'left'}}>As a policy, visitors are not required to register to gain access to areas of the BETA & Associates’ websites. In certain cases, in the future, as your BETA & Associates website experience expands, we may require visitors to register in order to obtain a user-id and password for authentication and secure access to a transaction or certain business confidential or proprietary information services on premium websites.</p>
                                <p style={{textAlign: 'left'}}>Personally, identifiable information provided to BETA & Associates through its website is provided voluntarily by visitors. Should visitors subsequently choose to unsubscribe from mailing lists or any registrations, we will provide instructions on the appropriate website area or in communications to our visitors; or a visitor may contact the webmaster of the appropriate site e.g., info@beta-associates.com</p>
                                <h3 style={{textAlign: 'left'}}>Access</h3>
                                <p style={{textAlign: 'left'}}>Each visitor has the right of access to personal data they have submitted through the websites to BETA & Associates.</p>
                                <p style={{textAlign: 'left'}}>User updates of information should be handled by going back through the registration process. Inquiries about the accuracy of identifying information previously submitted to BETA & Associates through its website, or requests to have outdated information removed, should be directed to: inof@beta-associates.com. BETA & Associates is committed to providing reasonable and practical access</p>
                            </div>
                            {/* <NavLink to="/">Learn more about the agency&#x02192;</NavLink> */}
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )

    }
}

export default PrivacyPolicy;