import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from "../components/header"
import Footer from "../components/footer"
import image from '../assets/to2.jpg';

class Owork extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <div className="App">
                <Header />
                <section>
                    <div className="sia2">
                        <img src={image} style={{objectFit: "contain", height: "100%", width: "100%"}} />
                    </div>
                    <div className="ag">
                        {/* <div className="h1 cb">Beta And Associates</div> */}
                        <div className="cc">
                            <div className="h2">Our Work.</div>
                            <div className="com">
                                <p style={{textAlign: 'left'}}>BETA & Associates (Chartered Accountants) is an audit and assurance, taxation and regulatory, accounting and bookkeeping, financial Advisory & consultancy, training and other services firm established to provide its clients and community with high quality and professional services. Our main goal is finding new ways to serve you through training and development, and recruiting competent specialist with required skills and experience in new areas so as to provide you with high quality service that you expect.  </p>
                                <p style={{textAlign: 'left'}}>Our vision is to be one of the leading Auditing and Consulting firms in Ghana in terms of providing professional services, number of employees and number of partners and representatives. At BETA & Associates, we are dedicated to quality. It is through offering our own expertise in various Assurance and Consulting services and maintaining an ongoing system of quality review that offer you professional services.  </p>
                                <p style={{textAlign: 'left'}}>We have over the years performed varied assignments as individuals and as a firm we therefore have enough experience to excellently execute the assignments should you engage our firm.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )

    }
}

export default Owork;