import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from "../components/header"
import Footer from "../components/footer"
import image from '../assets/mq1.png';
import image2 from '../assets/mb.png';

class International extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <div className="App">
                <Header />
                <section>
                    <div className="ag" style={{display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
                        {/* <div className="h1 cb">Services</div> */}
                        <div className="cc" style={{display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
                            <div style={{fontWeight: "bold", fontSize: 40, textAlign: "center"}}>Our International Affiliate</div>
                            <hr style={{backgroundColor: "#e2e2e2", width: "100%",}} />
                            <div className="sipl">
                                <img src={image2} alt="" style={{objectFit: "contain", height: "100%", width: "100%"}} />
                            </div>
                            <div className="lkvc">
                             Efficiency, Effectiveness And Economy
                            </div>
                            <div className="siper">
                                <img src={image} style={{objectFit: "contain", height: "100%", width: "100%"}} alt="" />
                            </div>
                            <div className="com">
                                <p style={{textAlign: 'left'}}>
                                    BETA & Associates (the “Firm”) is a member of 3E Accounting International Limited, a Singapore company, and forms part of the 3E Accounting International network of legally independent accounting and consulting firms. 3E is the brand name for the 3E Accounting international network. The services described herein are provided by BETA & Associates and not by 3E Accounting International or any other member firm of 3E Accounting International. Neither  nor any member of 3E Accounting International has any liability for services provided by other members.
                                </p>
                            </div>
                        </div>
                        <a href="https://www.3ecpa.com/worldwide-office/africa/ghana" target="_blank">Learn more about our affiliation&#x02192;</a>
                    </div>
                </section>
                <Footer />
            </div>
        )

    }
}

export default International;