import React from 'react';
import { NavLink } from 'react-router-dom';
import { IoIosArrowDown, IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import ab from '../assets/ab.png';

class Header extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            a: false,
            b: false,
            c: false,
            aa: false,
            bb: false,
            cc: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
        if (window.location.pathname === "/Services/Audit%20&%20Assurance" || window.location.pathname === "/Services/Taxation%20&%20Regulatory" || window.location.pathname === "/Services/Accounting%20&%20Bookkeeping" || window.location.pathname === "/Services/Financial%20Advisory" || window.location.pathname === "/Services/Financial%20Consultancy" || window.location.pathname === "/Services/Other%20Services" || window.location.pathname === "/Services/Financial%20Advisory%20&%20Consultancy") {
            this.setState({a: true});
        }
        if (window.location.pathname === "/Introduction,%20Mission,%20&%20Vision" || window.location.pathname === "/Core%20Values%20&%20Competencies" || window.location.pathname === "/International%20Affiliate") {
            this.setState({b: true});
        }
        if (window.location.pathname === "/The-Team" || window.location.pathname === "/Other-Staff" || window.location.pathname === "/Associates" || window.location.pathname === "/Quality%20Control%20Assurance%20Partner") {
            this.setState({c: true});
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <header className="flaw">
                <div className="header">
                    <div className="logo">
                        <img src={ab} style={{objectFit: "contain", height: "100%", width: "100%"}} />
                    </div>
                    <div onClick={() => this.setState({menu: true})} className="nv">
                        <div className="fi"></div>
                        <div className="se"></div>
                        <div className="seth"></div>
                    </div>
                    <div className="nav">
                        <NavLink onClick={() => this.setState({a: false})} activeClassName="active" exact to="/">Home{this.state.a}</NavLink>
                        {/* <NavLink onClick={() => this.setState({a: false})} activeClassName="active" to="/About">About Us</NavLink> */}
                        <div className="ser2">
                            <div style={{borderBottomWidth: this.state.b === true ? 3 : 0, borderBottomColor: "#f00", borderBottomStyle: "solid"}} className="serv3">
                                <div>About Us</div>
                                <IoIosArrowDown />
                            </div>
                            <div className="serv4">
                                <div className="ari2">
                                    <NavLink onClick={() => this.setState({b: true})} activeClassName="active3" to="/Introduction, Mission, & Vision">Introduction, Mission, & Vision</NavLink>
                                    <NavLink onClick={() => this.setState({b: true})} activeClassName="active3" to="/Core Values & Competencies">Core Values & Competencies</NavLink>
                                    <NavLink onClick={() => this.setState({b: true})} activeClassName="active3" to="/International Affiliate">International Affiliate</NavLink>
                                </div>
                            </div>
                        </div>
                        <NavLink onClick={() => this.setState({a: false})} activeClassName="active" to="/Our-Work">Our Work</NavLink>
                        <NavLink onClick={() => this.setState({a: false})} activeClassName="active" to="/Practice-Areas">Practice Areas</NavLink>
                        {/* <NavLink onClick={() => this.setState({a: false})} activeClassName="active" to="/Services">Services</NavLink> */}
                        <div className="ser">
                            <div style={{borderBottomWidth: this.state.a === true ? 3 : 0, borderBottomColor: "#f00", borderBottomStyle: "solid"}} className="serv">
                                <div>Services</div>
                                <IoIosArrowDown />
                            </div>
                            <div className="serv2">
                                <div className="ari">
                                    <NavLink onClick={() => this.setState({a: true})} activeClassName="active3" to="/Services/Audit & Assurance">Audit & Assurance</NavLink>
                                    <NavLink onClick={() => this.setState({a: true})} activeClassName="active3" to="/Services/Taxation & Regulatory">Taxation & Regulatory</NavLink>
                                    <NavLink onClick={() => this.setState({a: true})} activeClassName="active3" to="/Services/Accounting & Bookkeeping">Accounting & Bookkeeping</NavLink>
                                    <NavLink onClick={() => this.setState({a: true})} activeClassName="active3" to="/Services/Financial Advisory & Consultancy">Financial Advisory & Consultancy</NavLink>
                                    <NavLink onClick={() => this.setState({a: true})} activeClassName="active3" to="/Services/Training">Training</NavLink>
                                    <NavLink onClick={() => this.setState({a: true})} activeClassName="active3" to="/Services/Other Services">Other Services</NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="ser">
                            <div style={{borderBottomWidth: this.state.c === true ? 3 : 0, borderBottomColor: "#f00", borderBottomStyle: "solid"}} className="serv">
                                <div>The Team</div>
                                <IoIosArrowDown />
                            </div>
                            <div className="serv2">
                                <div className="ari">
                                    <NavLink onClick={() => this.setState({b: true})} activeClassName="active3" to="/The-Team">Partners</NavLink>
                                    <NavLink onClick={() => this.setState({b: true})} activeClassName="active3" to="/Associates">Associates</NavLink>
                                    <NavLink onClick={() => this.setState({b: true})} activeClassName="active3" to="/Quality Control Assurance Partner">Quality Control Assurance Partner</NavLink>
                                    <NavLink onClick={() => this.setState({b: true})} activeClassName="active3" to="/Other-Staff">Staff</NavLink>
                                </div>
                            </div>
                        </div>
                        {/* <NavLink onClick={() => this.setState({a: false})} activeClassName="active" to="/The-Team">The Team</NavLink> */}
                        <NavLink onClick={() => this.setState({a: false})} activeClassName="active" to="/Contact">Contact Us</NavLink>
                    </div>
                    {
                        this.state.menu ?
                            <div className="lit">
                                <div className="nav2">
                                    <div className="nbk">
                                        <div className="nmb">
                                            <button style={{fontWeight: 500, fontSize: 20}} onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({menu: false})
                                            }} >X</button>
                                        </div>
                                        {
                                            this.state.aa ?
                                            <div className="ncw">
                                                <div className="woa3" onClick={() => this.setState({aa: false})}>
                                                    <div className="wo">
                                                        <IoIosArrowBack />
                                                    </div>
                                                    <div style={{marginLeft: 10}}>Services</div>
                                                </div>
                                                <div className="woa">
                                                    <NavLink onClick={() => this.setState({a: true})} activeClassName="active3" to="/Services/Audit & Assurance">Audit & Assurance</NavLink>
                                                </div>
                                                <div className="woa">
                                                    <NavLink onClick={() => this.setState({a: true})} activeClassName="active3" to="/Services/Taxation & Regulatory">Taxation & Regulatory</NavLink>
                                                </div>
                                                <div className="woa">
                                                    <NavLink onClick={() => this.setState({a: true})} activeClassName="active3" to="/Services/Accounting & Bookkeeping">Accounting & Bookkeeping</NavLink>
                                                </div>
                                                <div className="woa">
                                                    <NavLink onClick={() => this.setState({a: true})} activeClassName="active3" to="/Services/Financial Advisory & Consultancy">Financial Advisory & Consultancy</NavLink>
                                                </div>
                                                <div className="woa">
                                                    <NavLink onClick={() => this.setState({a: true})} activeClassName="active3" to="/Services/Other Services">Other Services</NavLink>
                                                </div>
                                            </div>:null
                                        }
                                        {
                                            this.state.bb ?
                                            <div className="ncw">
                                                <div className="woa3" onClick={() => this.setState({bb: false})}>
                                                    <div className="wo">
                                                        <IoIosArrowBack />
                                                    </div>
                                                    <div style={{marginLeft: 10}}>About Us</div>
                                                </div>
                                                <div className="woa">
                                                    <NavLink onClick={() => this.setState({b: true})} activeClassName="active3" to="/Introduction, Mission, & Vision">Introduction, Mission, & Vision</NavLink>
                                                </div>
                                                <div className="woa">
                                                    <NavLink onClick={() => this.setState({b: true})} activeClassName="active3" to="/Core Values & Competencies">Core Values & Competencies</NavLink>
                                                </div>
                                                <div className="woa">
                                                    <NavLink onClick={() => this.setState({b: true})} activeClassName="active3" to="/International Affiliate">International Affiliate</NavLink>
                                                </div>
                                            </div>:null
                                        }
                                        {
                                            this.state.cc ?
                                            <div className="ncw">
                                                <div className="woa3" onClick={() => this.setState({cc: false})}>
                                                    <div className="wo">
                                                        <IoIosArrowBack />
                                                    </div>
                                                    <div style={{marginLeft: 10}}>The Team</div>
                                                </div>
                                                <div className="woa">
                                                    <NavLink onClick={() => this.setState({b: true})} activeClassName="active3" to="/The-Team">Partners</NavLink>
                                                </div>
                                                <div className="woa">
                                                    <NavLink onClick={() => this.setState({b: true})} activeClassName="active3" to="/Associates">Associates</NavLink>
                                                </div>
                                                <div className="woa">
                                                    <NavLink onClick={() => this.setState({b: true})} activeClassName="active3" to="/Quality Control Assurance Partner">Quality Control Assurance Partner</NavLink>
                                                </div>
                                                <div className="woa">
                                                    <NavLink onClick={() => this.setState({b: true})} activeClassName="active3" to="/Other-Staff">Staff</NavLink>
                                                </div>
                                            </div>:null
                                        }
                                        {
                                            this.state.aa || this.state.bb || this.state.cc ?
                                            null:
                                            <div className="ncw">
                                                <div className="woa">
                                                    <NavLink onClick={() => this.setState({a: false})} activeClassName="active" exact to="/">Home</NavLink>
                                                </div>
                                                <div className="woa2" onClick={() => this.setState({bb: true})}>
                                                    <div style={{marginLeft: 10}}>About Us</div>
                                                    <div className="wo">
                                                        <IoIosArrowForward />
                                                    </div>
                                                </div>
                                                <div className="woa">
                                                    <NavLink onClick={() => this.setState({a: false})} activeClassName="active" to="/Our-Work">Our Work</NavLink>
                                                </div>
                                                <div className="woa">
                                                    <NavLink onClick={() => this.setState({a: false})} activeClassName="active" to="/Practice-Areas">Practice Areas</NavLink>
                                                </div>
                                                <div className="woa2" onClick={() => this.setState({aa: true})}>
                                                    <div style={{marginLeft: 10}}>Services</div>
                                                    <div className="wo">
                                                        <IoIosArrowForward />
                                                    </div>
                                                </div>
                                                <div className="woa2" onClick={() => this.setState({cc: true})}>
                                                    <div style={{marginLeft: 10}}>The Team</div>
                                                    <div className="wo">
                                                        <IoIosArrowForward />
                                                    </div>
                                                </div>
                                                <div className="woa">
                                                    <NavLink onClick={() => this.setState({a: false})} activeClassName="active" to="/Contact">Contact Us</NavLink>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>:
                            null
                    }
                </div>
            </header>
        )

    }
}

export default Header;