import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from "../components/header"
import Footer from "../components/footer"
import image from '../assets/cb.jpg';

class S2 extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <div className="App">
                <Header />
                <section>
                    <div className="sip2">
                        <img src={image} style={{objectFit: "contain", height: "100%", width: "100%"}} />
                    </div>
                    <div className="ag">
                        {/* <div className="h1 cb">Services</div> */}
                        <div className="cc">
                            <div className="h2">Taxation and Regulatory </div>
                            <div className="com">
                                <p style={{textAlign: 'left'}}>Since our watchword is creating value in an excellent manner, our tax management service is top-notch. At BETA, we are fully aware that due to technological advancement, the business atmosphere globally, is becoming increasingly charged. As such, tax management and planning are now taking different dimensions. However, with our leading-edge technology and our experience in outsourcing and consulting, we help clients resolve their tax management issues. We have a track record of helping corporate businesses to address challenges on their tax operations in the different areas. We offer many and varied taxation services including: </p>
                                <ul style={{textAlign: 'left'}}>
                                    <li>Income Tax Returns  </li>
                                    <li>Financial Reports for all types of SMEs businesses  </li>
                                    <li>Foreign owned companies Taxation Reports.  </li>
                                    <li>Business Activity Statements for all types of SME businesses   </li>
                                    <li>Tax Planning</li>
                                    <li>Tax Advisory</li>
                                    <li>GRA Representation </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )

    }
}

export default S2;